<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>订单管理</p>
			<i>/</i>
			<p @click="jumpPage">企业资产单列表</p>
			<i>/</i>
			<p class="active">关联订单</p>
		</nav>
		
		<div class="head_search_wrap">
		
			<div class="search_one_line">
				<div class="left">
					<el-form label-width="100px">
						<el-form-item label="套餐名称：">
							<el-input class="input_medi" v-model="keyword" clearable placeholder="请输入套餐名称"></el-input>
						</el-form-item>
						<el-form-item label="状态：">
							<el-select v-model="type" placeholder="全部状态">
								<el-option label="作废" value="-1"></el-option>
								<el-option label="待提交" value="1"></el-option>
								<el-option label="待技术确认" value="10"></el-option>
								<el-option label="待财务确认" value="15"></el-option>
								<el-option label="待工程验收" value="20"></el-option>
								<el-option label="工程验收完成" value="30"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="下单时间：" label-width="120px">
							<el-date-picker class="input_long" v-model="placeTime" value-format="yyyy-MM-dd HH:mm:ss"
								type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</el-form>
				</div>
				<div class="right">
					<p class="reset" @click="resetFn">重置</p>
					<p class="search" @click="searchFn">查询</p>
				</div>
			</div>
		
		</div>
		
		<div class="page_table">
	
			<el-table :data="listData" class="tb-edit" style="width: 100%" highlight-current-row>
				<el-table-column prop="order_sn" label="订单号" align="left"></el-table-column>
				<el-table-column prop="create_time" label="下单时间" align="center"></el-table-column>
				<el-table-column prop="custom_name" label="企业名称" align="center"></el-table-column>
				<el-table-column prop="order_content" label="资产内容" align="center"></el-table-column>
				<el-table-column prop="order_amount" label="订单金额" align="center"></el-table-column>
				<el-table-column prop="useful_life" label="时长(年)" align="center"></el-table-column>
				<el-table-column prop="" label="状态" align="center">
					<template slot-scope="scope">
						<div class="orderr_status">
							<p v-if="scope.row.order_status == '0'" class="order_status_p1">作废</p>
							<p v-else-if="scope.row.order_status == '1'" class="order_status_p4">待提交</p>
							<p v-else-if="scope.row.order_status == '10'" class="order_status_p2">技术确认</p>
							<p v-else-if="scope.row.order_status == '15'" class="order_status_p2">财务确认</p>
							<p v-else-if="scope.row.order_status == '20'" class="order_status_p2">工程验收</p>
							<p v-else-if="scope.row.order_status == '30'" class="order_status_p3">工程验收完毕</p>
						</div>
					</template>
				</el-table-column>
			</el-table>
			
			<div class="block">
				<el-pagination @current-change="currentChange" :current-page="currentPage1" :total="total">
				</el-pagination>
			</div>
			
		</div>
	
	</div>
</template>

<script>
	
	export default {
		name: 'relationOrder',
		data() {
			return {
				listData: [], // 列表数据
				total: 0, //总页数
				currentPage1: 1, //当前页码
				keyword: '', // 关键字搜索
				type: '', // 状态
				placeTime: '', // 按下单时间搜索
			}
		},
		components: {
			
		},
		created() {
			// 获取续费关联单
			this.getRelationOrder();
		},
		methods: {
			
			// 获取续费关联单
			getRelationOrder(){
				let start_time = '';
				let end_time = '';
				
				if (this.placeTime.length > 0) {
					start_time = this.placeTime[0];
					end_time = this.placeTime[1];
				}
				
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}order/paging`,
					data: {
						order_ids: this.$route.query.id,
						page: this.currentPage1,
						keyword: this.keyword,
						start_time: start_time,
						end_time: end_time,
						order_status: this.type,
					}
				}).then( res => {
					if(res.code == '0'){
						this.total = res.count;
						this.listData = res.data.list;
					}
				})
			},
			
			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},
			
			// 头部跳转页面
			jumpPage(){
				this.$router.go(-1);
			},
			
			// 分页器函数
			currentChange(val) {
				this.currentPage1 = val;
				this.getRelationOrder();
			},
			
			// 重置
			resetFn() {
				this.currentPage1 = 1;
				this.placeTime = '';
				this.keyword = '';
				this.type = '';
				this.getRelationOrder();
			},
			
			// 搜索
			searchFn() {
				this.currentPage1 = 1;
				this.getRelationOrder();
			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}
	
	.orderr_status {
		.order_status_p1 {
			font-size: 14px;
			color: red;
		}
	
		.order_status_p2 {
			font-size: 14px;
			color: #409EFF;
		}
	
		.order_status_p3 {
			font-size: 14px;
			color: #EE7B1D;
		}
		.order_status_p4{
			font-size: 14px;
			color: #67C23A
		}
	}
	
	.head_search_wrap .search_one_line {
		align-items: flex-start;
	}

</style>
