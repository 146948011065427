<template>

	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>订单管理</p>
			<i>/</i>
			<p>企业资产单列表</p>
		</nav>

		<div class="head_search_wrap">

			<div class="search_one_line">
				<div class="left">
					<el-form label-width="100px">
						<el-form-item label="关键字搜索：">
							<el-input class="input_medi" v-model="keyword" placeholder="定单号/客户名称/资产内容"></el-input>
						</el-form-item>
						<el-form-item label="创建时间：" label-width="120px">
							<el-date-picker class="input_long" v-model="placeTime" value-format="yyyy-MM-dd HH:mm:ss"
								type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</el-form>
				</div>
				<div class="right">
					<p class="reset" @click="resetFn">重置</p>
					<p class="search" @click="searchFn">查询</p>
				</div>
			</div>

		</div>

		<div class="page_table">
			<div class="page_table_title">
				<div class="left">
					<p>企业资产单列表</p>
				</div>
			</div>
			<el-table :data="listData" class="tb-edit" style="width: 100%" highlight-current-row>
				<el-table-column prop="saas_order_sn" label="企业资产单号" align="left"></el-table-column>
				<el-table-column prop="update_time" label="最近一次更新时间" align="center"></el-table-column>
				<el-table-column prop="custom_name" label="企业名称" align="center"></el-table-column>
				<el-table-column prop="content" label="资产内容" align="center"></el-table-column>
				<el-table-column prop="useful_life" label="时长(年)" align="center"></el-table-column>
				<el-table-column prop="" label="状态" align="center">
					<template slot-scope="scope">
						<div class="orderr_status">
							<p v-if="scope.row.status == '0'">已过期</p>
							<p v-if="scope.row.status == '1'">即将过期</p>
							<p v-else-if="scope.row.status == '10'">生效</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="effect_time" label="生效日期" align="center"></el-table-column>
				<el-table-column prop="end_time" label="到期日期" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="140px">
					<template slot-scope="scope">
						<div class="operation">
							<span @click="renewFn(scope.row)">续费</span>
							<span @click="relationFn(scope.row)">关联订单</span>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="block">
				<el-pagination @current-change="currentChange" :current-page="currentPage1" :total="total">
				</el-pagination>
			</div>

		</div>

	</div>




</template>

<script>
	export default {
		name: 'enterpriseAssetList',
		data() {
			return {
				listData: [], // 列表数据
				total: 0, //总页数
				currentPage1: 1, //当前页码
				keyword: '', // 关键字搜索
				type: '', // 状态
				placeTime: '', // 按下单时间搜索
			}
		},
		components: {},
		created() {
			// 获取企业资产单列表
			this.getAssetsList();
		},
		methods: {
			// 重置
			resetFn() {
				this.currentPage1 = 1;
				this.placeTime = '';
				this.keyword = '';
				this.getAssetsList();
			},

			// 搜索
			searchFn() {
				this.currentPage1 = 1;
				this.getAssetsList();
			},
			
			// 获取企业资产单列表
			getAssetsList(){
				let start_time = '';
				let end_time = '';
				
				if(this.placeTime.length > 0){
					start_time = this.placeTime[0];
					end_time = this.placeTime[1];
				}
				
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}saasorder/paging`,
					data: {
						page: this.currentPage1,
						keyword: this.keyword,
						start_time: start_time,
						end_time: end_time,
					}
				}).then( res => {
					if(res.code == '0'){
						this.total = res.count;
						this.listData = res.data.list;
					}
				})
			},

			// 分页器函数
			currentChange(val) {
				this.currentPage1 = val;
				this.getAssetsList();
			},

			// 续费
			renewFn(row) {
				this.$router.push({
					path: '/enterpriseOrderEdit',
					query: {
						saas_order_sn: row.saas_order_sn,
						order_cat: '2',
					}
				});
			},
			
			// 关联订单
			relationFn(row){
				this.$router.push({
					path: '/relationOrder',
					query: {
						id: row.relate_order_ids
					}
				});
			}
			
		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 300px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}
</style>
