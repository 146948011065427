<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>订单管理</p>
			<i>/</i>
			<p @click="jumpPage">企业资产单</p>
			<i>/</i>
			<p class="active">续费单详情</p>
		</nav>

		<div class="head_search_wrap">

			<div class="search_one_line">
				<div class="left">
					<el-form label-width="120px" @submit.native.prevent>
						<el-form-item label="名称:">
							<el-input class="input_medi" v-model="keyword" @keyup.enter.native="searchFn" clearable
								placeholder="请输入名称"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="right">
					<p class="reset" @click="resetFn">重置</p>
					<p class="search" @click="searchFn">查询</p>
				</div>
			</div>

		</div>

		<div class="page_table">
			<div class="page_table_title">
				<div class="left">
					<p>列表</p>
				</div>
				<div class="right">
					<span class="span1" @click="addFn">新增</span>
				</div>
			</div>
			<el-table :data="listData" class="tb-edit" style="width: 100%" highlight-current-row>
				<el-table-column prop="account_sn" label="会员编号" align="left"></el-table-column>
				<el-table-column prop="header_img" label="会员头像" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.header_img">
							<img class="goods_table_img" :src="scope.row.header_img" alt="">
						</div>
						<div v-else>
							<p>"-"</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="member_phone" label="会有手机号" align="center"></el-table-column>
				<el-table-column prop="account_name" label="会员名称" align="center"></el-table-column>
				<el-table-column prop="member_title_name" label="会员等级" align="center"></el-table-column>
				<el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="140px">
					<template slot-scope="scope">
						<div class="operation">
							<span @click="modifyFn(scope.row)">修改</span>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="block">
				<el-pagination @current-change="currentChange" :current-page="currentPage1" :total="total">
				</el-pagination>
			</div>

		</div>



	</div>
</template>

<script>
	
	export default {
		name: 'renewInfo',
		data() {
			return {
				listData: [], // 列表数据
				total: 0, //总页数
				currentPage1: 1, //当前页码
				keyword: '', // 关键字搜索
			}
		},
		components: {
			
		},
		created() {

		},
		methods: {
			// 重置
			resetFn() {
				this.currentPage1 = 1;
				this.keyword = '';
			},

			// 搜索
			searchFn() {
				this.currentPage1 = 1;
			},

			// 分页器函数
			currentChange(val) {
				this.currentPage1 = val;
			},

			// 修改
			modifyFn(row) {
				console.log(row)
				this.$router.push({
					path: '/',
					query:{
						id: row.id,
					}
				})
			},

			// 新增
			addFn() {
				this.$router.push('/Edit');
			},
			
			// 点击去往上一页
			jumpPage(){
				this.$router.push('/enterpriseAssetList')
			}
		}
	}
</script>
<style scoped lang="less">
	.operation {
		span {
			padding: 8px;
			font-size: 14px;
			color: #EE7B1D;
			cursor: pointer;
		}
	}
</style>
